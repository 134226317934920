<template>
  <el-main>
    <el-form :model="formData" ref="form" :rules="rules" label-width="160px" class="formData">
      <el-form-item label="选择店铺" prop="auto_cancel_time">
        <div class="contentBox">
          <el-button :disabled="isEdit" type="primary" size="mini" @click="show_choose_store = true">选择店铺</el-button>
          <!-- 显示选中的店铺 -->
          <UserInfo v-if="formData.store_info.id" :name="formData.store_info.store_name" :avatar="formData.store_info.store_logo"></UserInfo>
        </div>
      </el-form-item>
      <el-form-item label="选择商品" prop="auto_cancel_time">
        <div class="contentBox">
          <el-button :disabled="isEdit" type="primary" size="mini" @click="openChooseGoods">选择商品</el-button>
          <!-- 显示选中的店铺 -->
          <UserInfo v-if="formData.goods_info.id" :avatar="formData.goods_info.goods_picture" :name="formData.goods_info.goods_name"></UserInfo>
        </div>
      </el-form-item>
      <el-form-item label="是否填入默认数据" prop="auto_cancel_time">
        <el-button type="primary" size="mini" @click="inDefaultData">填入</el-button>
      </el-form-item>
      <el-form-item label="可开人数" prop="auto_cancel_time">
        <el-input placeholder="请输入内容" v-model="formData.people_min" type="number" :min="1">
          <template slot="append">人</template>
        </el-input>
      </el-form-item>
      <el-form-item label="最多人数" prop="auto_cancel_time" :max="2">
        <el-input placeholder="请输入内容" v-model="formData.people_max" type="number">
          <template slot="append">人</template>
        </el-input>
      </el-form-item>
      <el-form-item label="支持合伙人免单" prop="auto_cancel_time">
        <el-switch v-model="formData.main_free" active-value="1" inactive-value="2"></el-switch>
      </el-form-item>
      <el-form-item label="均摊模式" prop="auto_cancel_time">
        <el-radio-group disabled v-model="formData.deposit">
          <el-radio :label="2">定金模式</el-radio>
          <el-radio :label="1">全额模式</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="定金金额" v-if="formData.deposit == 2" prop="auto_cancel_time">
        <el-input placeholder="请输入内容" v-model="formData.deposit_money"></el-input>
      </el-form-item>
      <el-form-item label="是否启用" prop="auto_cancel_time">
        <el-switch v-model="formData.status" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item label="可用时间段" prop="auto_cancel_time">
        <!-- <el-button type="primary" size="mini">+ 添加时间段</el-button> -->
        <div class="timesBox">
          <div class="chooseBtn">
            <el-time-picker
              format="HH:mm"
              is-range
              v-model="chooseTime"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              value-format="HH:mm"
              size="small"
            ></el-time-picker>
            <el-button type="primary" size="small" @click="getTime">添加</el-button>
          </div>
          <div class="allow_time" v-for="(item, index) in formData.allow_time" :key="index">
            <div>{{ item }}</div>
            <div class="btn" @click="formData.allow_time.splice(index, 1)">删除</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="">
        <el-tabs v-model="richTxtShow" style="width: 600px">
          <el-tab-pane label="活动内容" name="first">
            <RichText v-if="richTxtShow == 'first'" width="600" height="400" editorId="content" :richTxt="formData.content" @getContent="getContent"></RichText>
          </el-tab-pane>
          <el-tab-pane label="活动规则" name="second">
            <RichText v-if="richTxtShow == 'second'" width="600" height="400" editorId="rule" :richTxt="formData.rule" @getContent="getContent"></RichText>
          </el-tab-pane>
        </el-tabs>
      </el-form-item>
    </el-form>

    <Preservation @preservation="Preservation"></Preservation>
    <!-- 显示选择店铺 -->
    <el-dialog title="选择店铺" :visible.sync="show_choose_store" width="700px">
      <ListComponents :styleMethon="1" @getListItem="getChooseStore"></ListComponents>
    </el-dialog>
    <!-- 显示选择商品 -->
    <el-dialog title="选择商品" :visible.sync="show_choose_goods" width="700px">
      <ListComponents :store_id="formData.store_info.id" :styleMethon="0" @getListItem="getChooseGoods"></ListComponents>
    </el-dialog>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import RichText from '@/components/richText';
import ListComponents from '@/components/listComponents';
import UserInfo from '@/components/userInfo.vue';
export default {
  components: {
    Preservation,
    RichText,
    ListComponents,
    UserInfo,
  },
  data() {
    return {
      show_choose_store: false,
      show_choose_goods: false,
      richTxtShow: 'first',
      formData: {
        store_info: {},
        goods_info: {},
        people_min: '',
        people_max: '',
        main_free: 1,
        deposit: 1,
        deposit_money: '',
        status: 1,
        content: '',
        rule: '',
        allow_time: [],
        is_spec: 1,
        classify_id: null,
      },
      chooseTime: ['', ''],
      isEdit: 0,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.isEdit = this.$route.query.id;
      this.getDataInfo();
    }
  },
  methods: {
    inDefaultData() {
      this.formData.people_min = 3;
      this.formData.people_max = 8;
      this.formData.allow_time = ['08:00 - 23:00'];
      this.formData.rule =
        '活动规则:致尊敬的客户(以下称“贵方”我方将在遵守国家有关法律、法规和社会公序良俗的基础上提供服务，望尊敬的顾客们也严格遵守，我方就有关事项严正声明，告知如下告知事项1、凡在签署本协议均视为具有完全民事行为能力人且认同并自愿遵守本协议2、所有贵方必须严格遵守我方的相关规定以及本协议的有关内容，必须对自己的安全负责，因贵方违反下述规定在酒吧内发生任何的意外和损失，我方均不承担责任。3、患有心脏病、高血压、幽闭恐惧症、癫痴、精神疾病、酗酒等相关疾病或身体不佳的贵方，请勿入内;怀有身孕者，请勿入内;年龄超过30周岁的贵方，需要告知工作人员禁止行为贵方禁止在我方平台下单后在酒吧打架斗殴、寻衅滋事贵方不得利用我方小程序平台买卖、吸食冰毒、白粉、摇头丸、大麻、笑气等精神上瘾性的药物、毒品等。4、贵方不得从事吸毒、赌博、斗殴等违法犯罪活动或其他违反公共伦理道德的行为。三、责任承担1、若在没有告知工作人员或禁止入内等情况的，其发生的任何意外、疾病、受伤等情况由贵方自行承担责任，我方无需负责。2、凡在进入酒吧前因个人原因或隐瞒个人实际情况，提交虚假个人信息，由本人承担一切责任。3、如因贵方个人原因而产生的不愉快矛盾或对立，或因冲突升级而造成的直接或间接财产、精神及健康损失，或因个人原因发生的推操、碰撞、摔倒疾病等导致的伤亡，由贵方自行负责';
    },
    openChooseGoods() {
      if (!this.formData.store_info.id) {
        this.$message.warning('请先选择店铺');
        return;
      }
      this.show_choose_goods = true;
    },
    getDataInfo() {
      this.$axios
        .post(this.$api.yiyun.goodsInfo, {
          id: this.isEdit,
        })
        .then(res => {
          if (res.code == 0) {
            for (const key in this.formData) {
              if (key == 'allow_time') {
                this.formData[key] = res.result['allow_times'];
              } else {
                this.formData[key] = res.result[key];
              }
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getChooseStore(data) {
      this.formData.store_info = data;
      this.show_choose_store = false;
    },
    getChooseGoods(data) {
      this.formData.goods_info = data;
      this.show_choose_goods = false;
    },
    getContent(e, type) {
      if (type == 'content') {
        this.formData.content = e;
      } else {
        this.formData.rule = e;
      }
    },
    getTime() {
      if (!this.chooseTime[0]) {
        this.$message.warning('请选择时间');
        return;
      }
      let str = this.chooseTime.join(' - ');
      this.formData.allow_time.push(str);
    },
    Preservation() {
      let obj = {
        store_id: this.formData.store_info.id,
        goods_id: this.formData.goods_info.id,
        classify_id: this.formData.goods_info.classify_id,
        people_min: this.formData.people_min,
        people_max: this.formData.people_max,
        main_free: this.formData.main_free,
        deposit: this.formData.deposit,
        status: this.formData.status,
        content: this.formData.content,
        rule: this.formData.rule,
        allow_time: this.formData.allow_time,
        is_spec: 0,
      };
      obj.deposit_money = obj.deposit == 2 ? this.formData.deposit_money : 0;
      this.$axios.post(this.$api.yiyun.goodsEdit, obj).then(res => {
        if (res.code == 0) {
          this.$message.success('添加成功');
          this.$router.push('/extension/packageManagement/packageManagement');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  padding-bottom: 100px;
  .el-form {
    .el-input {
      width: 400px;
    }
  }
  .chooseBtn {
    display: flex;
    align-items: center;
    .el-button {
      margin-left: 10px;
    }
  }
  .allow_time {
    display: flex;
    line-height: 20px;
    .btn {
      margin-left: 10px;
      color: #f56c6c;
      cursor: pointer;
    }
  }
}
</style>
